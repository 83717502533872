<template>
  <div class="main-content">
    <TableComponent
        section="client.Client"
        sectionRoot="client_list"
        moduleName="Client"
    ></TableComponent>
  </div>
</template>

<script>
import TableComponent from '@/components/common/other/table/TableComponent';

export default {
  name: 'Table',
  components: {
    TableComponent

  }
};
</script>
